export const SPECS = {
  LimitMaxGiftCardCreateAmount:
    'specs.stores.LimitMaxGiftCardCreateAmountViewer',
  AddNoPaymentMethodsModal: 'specs.stores.AddNoPaymentMethodsModal',
  UseGetOrCreateGiftCard: 'specs.stores.UseGetOrCreateGiftCard',
  GiftCardAddToCartSettings: 'specs.stores.ShowGiftCardAddToCartSettings',
  giftCardBuyNowSettings: 'specs.stores.ShowGiftCardBuyNowSettings',
  GiftCardDesignCapabilities: 'specs.stores.GiftCardDesignCapabilities',
  RecipientInputFieldsCornerRadius:
    'specs.stores.RecipientInputFieldsCornerRadius',
};

export enum PanoramaTransactions {
  giftCardProductsExist = 'gift-card-products-exist',
  giftCardCreateDefaultProduct = 'gift-card-create-default-product',
  giftCardBuyNow = 'gift-card-buy-now',
  giftCardAddToCart = 'gift-card-add-to-cart',
}

export const DEFAULT_AMOUNT_MAX_LIMIT = 1000000000;

export const DEFAULT_IMAGE_WIDTH_DESKTOP = 452;
export const DEFAULT_IMAGE_WIDTH_MOBILE = 335;

export const currencyToMaxAllowedBalanceMap: { [key: string]: number } = {
  USD: 2000,
  EUR: 8100,
  AUD: 14000,
  GBP: 7500,
  CAD: 13000,
  AFN: 672400,
  ALL: 897700,
  DZD: 1294600,
  AOA: 8700400,
  XCD: 25700,
  ARS: 9814100,
  AMD: 3799600,
  AWG: 17100,
  AZN: 16200,
  BSD: 9500,
  BHD: 3500,
  BDT: 1141700,
  BBD: 19200,
  BYN: 31200,
  BZD: 19100,
  XOF: 6002400,
  BTN: 817100,
  BOB: 66000,
  BAM: 17800,
  BWP: 132800,
  BND: 12900,
  BGN: 17800,
  BIF: 28247900,
  KHR: 38347900,
  CVE: 1008900,
  KYD: 7900,
  CLP: 9474200,
  CNY: 69600,
  COP: 41999900,
  KMF: 4446800,
  CDF: 27174800,
  CRC: 4846200,
  CUP: 229200,
  CZK: 230900,
  DKK: 68300,
  DJF: 1700000,
  DOP: 580800,
  EGP: 485200,
  ETB: 1218900,
  FJD: 22100,
  GMD: 686800,
  GEL: 26800,
  GHS: 140400,
  GTQ: 73500,
  GNF: 82559500,
  GYD: 1997500,
  HTG: 1248900,
  HNL: 242700,
  HKD: 74000,
  HUF: 3762700,
  ISK: 1321700,
  INR: 816200,
  IDR: 153775200,
  IRR: 401514700,
  IQD: 12507200,
  ILS: 34900,
  JMD: 1486900,
  JPY: 1507000,
  JOD: 6700,
  KZT: 4999900,
  KES: 1233900,
  KRW: 14071200,
  KWD: 2900,
  KGS: 829900,
  LAK: 208770000,
  LBP: 855602200,
  LSL: 178900,
  LRD: 1738500,
  LYD: 46900,
  MKD: 563300,
  MGA: 44811100,
  MWK: 16563700,
  MYR: 42600,
  MVR: 146900,
  MUR: 448200,
  MXN: 194400,
  MDL: 176000,
  MAD: 96300,
  MZN: 609500,
  NAD: 178900,
  NPR: 1307500,
  ANG: 17200,
  NZD: 16800,
  NIO: 351500,
  NGN: 14784100,
  NOK: 108400,
  OMR: 3600,
  PKR: 2659100,
  PAB: 9500,
  PGK: 38200,
  PYG: 74268000,
  PEN: 35700,
  PHP: 553300,
  PLN: 39100,
  QAR: 34800,
  RON: 45500,
  RUB: 1030300,
  RWF: 13185200,
  SHP: 7400,
  SAR: 35700,
  RSD: 1071100,
  SCR: 132400,
  SLL: 188415000,
  SGD: 12900,
  SBD: 79900,
  SOS: 5459300,
  ZAR: 179100,
  LKR: 2793700,
  SDG: 5738300,
  SRD: 334600,
  SEK: 104900,
  CHF: 8600,
  TJS: 104300,
  TZS: 23086800,
  THB: 325500,
  TOP: 22300,
  TTD: 64800,
  TND: 30400,
  TRY: 336300,
  TMT: 33400,
  UGX: 35032200,
  UAH: 400800,
  AED: 35000,
  UYU: 418400,
  UZS: 123421400,
  VND: 243126700,
  YER: 2388500,
  ZMW: 264800,
};

export const ERROR_CODES = {
  SiteMustAcceptPayments: 'SITE_MUST_ACCEPT_PAYMENTS_TO_CREATE_CHECKOUT',
};
